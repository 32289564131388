.MuiStep-root{
    flex: 100%;
    display: flex;
    height: 64px;
    padding: 20px 20px 20px 40px !important;
    background: rgb(0, 0, 0, 0.1);
    margin: 0 0 0 -15px;
    clip-path: polygon(20px 50%, 0% 0%, calc(100% - 20px) 0%, 100% 50%, calc(100% - 20px) 100%, 0% 100%);
}

.step-active{
    background: #263c7a;
}

.step-active span{
    color: #fff !important;
}

.MuiStepConnector-horizontal{
    display: none;
}

.Mui-completed{
    background: #66b6ff;
    color: #fff !important;
}

.stepper-btn-group{
    width: 250px;
}

.stepper-btn-group button{
    width: 50%;
}

.btn-lightgray{
    background: rgb(0, 0, 0, 0.3);
    color: #fff;
    width: 125px;
}
/* Modal */
.ReactModalPortal > div{
    opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
    align-items: center;
    display: flex;
    justify-content: center;
    transition: opacity .2s ease-in-out;
    z-index: 999;
}


.modal-fondo {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    position: fixed;
}


.modal-fondo-sms-sidebarclosed {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 88px;
    right: 0;
    top: 0;
    position: fixed;
}

.modal-fondo-sms-sidebaropened {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 250px;
    right: 0;
    top: 0;
    position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
    opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
    opacity: 0;
}

.modal {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    /* max-height: 620px; */
    max-width: 500px;
    outline: none;
    padding: 10px;
}


.modal-confirm {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-height: 250px;
    max-width: 500px;
    outline: none;
    padding: 10px;
}


.modal-newgroup {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-height: 320px !important;
    max-width: 500px;
    min-width: 400px;
    outline: none;
    padding: 10px;
}

.modal-schedule {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    height: auto !important;
    max-width: 500px;
    min-width: 400px;
    outline: none;
    padding: 2rem 3rem;
    z-index: 99999;
}

.modal-template {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    /* height: 230px !important; */
    max-width: 600px;
    min-width: 500px;
    outline: none;
    padding: 40px 40px;
}

.modal-returnToQR{
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    height: 235px !important;
    max-width: 500px;
    min-width: 400px;
    outline: none;
    padding: 2rem 3rem;
}

.label-width{
    width: 55px;
}

.label-width-3{
    width: 100px;
}


.modal-info{
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    /* height: 235px !important; */
    max-width: 600px;
    min-width: 500px;
    outline: none;
    padding: 2rem 3rem;
}


.modal-sms-limit{
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    /* height: 235px !important; */
    max-width: 400px;
    min-width: 300px;
    outline: none;
    padding: 2rem 3rem;
}


.modal-important-info{
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-width: 600px;
    min-width: 500px;
    max-height: 85vh;
    overflow-y: scroll;
    outline: none;
    padding: 2rem 3rem;
}

.modal-delete-credentials{
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-width: 600px;
    min-width: 500px;
    outline: none;
    padding: 1.8rem 2.5rem;
}

.modal-deletecontact{
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    /* height: 235px !important; */
    max-width: 500px;
    min-width: 400px;
    outline: none;
    padding: 2rem 3rem;
}


.modal-usersettings{
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    width: 500px;
    outline: none;
    padding: 2rem 3rem;
}

.modal-new-template{
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    width: 500px;
    outline: none;
    padding: 2rem 3rem;
}


.modal-multisession{
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-width: 600px;
    min-width: 400px;
    outline: none;
    padding: 1.5rem 3rem;
}

.modal-select-list {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-width: 800px;
    min-width: 400px;
    outline: none;
    padding: 10px;
}


.modal-mfa {
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    max-height: 95vh !important;
    max-width: 500px;
    min-width: 400px;
    outline: none;
    padding: 10px;
}

#tenantPhone span{
    background-color: #fff;
}

#tenantPhone input{
    border-left: none;
}

#tenantPhone span, #tenantPhone input{
    font-size: 20px;
    font-weight: 500;
    color: #263C7A;
}

.codeNumber{
    height: 80px;
    font-size: 35px;
    font-weight: 600;
    color: #263C7A;
}

.bxs-user-check{
    font-size: 40px;
}

.credentials-label{
    font-weight: 600;
}

.modal-new-extraction{
    background: white;
    border-radius: 5px;
    color: rgb(51, 51, 51);
    display: inline;
    outline: none;
    padding: 15px 40px 15px 40px;
}

.modal-sidebar-open{
    width: calc(100vw - 300px);
    height: calc(100vh - 40px);
}

.modal-sidebar-close{
    width: calc(100vw - 138px);
    height: calc(100vh - 40px);
}

.modal-fondo-sidebar-closed {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 88px;
    right: 0;
    top: 0;
    position: fixed;
}

.modal-fondo-sidebar-opened {
    background-color: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 250px;
    right: 0;
    top: 0;
    position: fixed;
}
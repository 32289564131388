body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.x-size{
  font-size: 20px;
}

.attach-icon{
  border-radius: 50%;
  font-size: 35px;
  float: right;
  color: #263c7a;
  cursor: pointer;
  margin: 5px 0;
}

.attachicon-position{
  position: absolute;
  bottom: 0;
  right: 0;
}

.attach-ul{
  list-style: none;
}

.btn-removeattach{
  font-size: 12px;
  padding: 0 4px;
}

.session-info{
  font-weight: 500;
  color: #263c7a;
}

.edit-icon{
  border-radius: 50%;
  padding: 5px;
  color: #fff;
  transition: .2s;
}

.check-icon{
  font-size: 18px;
  border-radius: 50%;
  padding: 3px;
  color: #fff;
  /* transition: .2s; */
}

.edit-icon:hover{
  background-color: #fff;
  padding: 5px;
  color: #263c7a;
}

.check-icon:hover{
  background-color: #fff;
  border-radius: 50%;
  padding: 3px;
  color: #263c7a;
}

.check-icon{
  font-weight: 700;
}

.edit-input, .edited-input{
  width: 95%;
  padding: 0 3px;
}

th, td{
  border: none;
}

#table .table td{
  max-width: 150px;
  /* max-width: 50% !important; */
  overflow: hidden;
}

.point-success{
  border: 1px solid #42ab49;
  color: rgb(66, 171, 73, 0.05);
  background-color: rgb(66, 171, 73, 0.7);
  border-radius: 50%;
  font-size: 9px;
}

.point-danger{
  border: 1px solid #d1052a;
  color: rgb(209, 5, 42, 0.05);
  background-color: rgb(209, 5, 42, 0.7);
  border-radius: 50%;
  font-size: 9px;
}

.point-warning{
  border: 1px solid #efb810;
  color: rgb(239, 184, 16, 0.05);
  background-color: rgb(239, 184, 16, 0.7);
  border-radius: 50%;
  font-size: 9px;
}

.status-success{
  color: #42ab49;
  font-weight: 500;
}

.status-danger{
  color: #d1052a;
  font-weight: 500;
}

.status-warning{
  color: #efb810;
  font-weight: 500;
}

.table-text{
  font-weight: 500;
}

.icon-size-m{
  font-size: 20px;
}

.template-modal-input label{
  color: rgb(0, 0, 0, 0.7);
  font-weight: 500;
  font-size: 15px;
}

.template-modal-input input::placeholder{
  text-align: center;
  font-weight: 400;
  font-size: 15px;
}

.template-modal-input input{
  padding: 5px 10px;
  border-radius: 5px;
  border: 2px solid rgb(0, 0, 0, 0.7);
  text-align: center;
  font-size: 20px !important;
}

.message-container{
  height: 45vh;
}

#wa-text{
  font-size: 0.9rem;
  line-height: 1.5;
  padding: 6px 12px;
}

.rta__autocomplete{
  font-size: 0.7rem;
}

.slate-container{
  position: relative !important;
}

.EmojiPickerReact{
  position: absolute !important;
  z-index: 10;
  margin: 15% 0 0 25%;
}

.insert-emoji{
  display: flex !important;
}

.slate-container{
  border: 1px solid rgb(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 12px 0px 0px 0px;
  height: 100%;
}

.editable-slate-content{
  height: calc(100% - 44px);
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
}

.editable-slate-content p span{
  margin-top: 10px;
}

/* width */
.editable-slate-content::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.editable-slate-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}
 
/* Handle */
.editable-slate-content::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
.editable-slate-content::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.css-1rh39ed{
  margin: 0 !important;
}

.antiblocking-info li small u {
  font-weight: 500;
}
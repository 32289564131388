.data-block{
    display: flex;
    flex-direction: column;
}

.data-block small{
    color: #697586;
    font-size: 11px;
    font-weight: 500;
    line-height: 100%;
    margin: 15px 0 0 0;
}

.data-block span{
    color: #263c7a;
    font-size: 13px;
    font-weight: 500;
    line-height: 100%;
}

.associated-fields{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.associated-fields small{
    color: #697586;
    font-size: 11px;
    font-weight: 500;
    line-height: 100%;
}

.associated-fields div div{
    display: flex;
    align-items: center;
    line-height: 100%;
}

.associated-fields div div small{
    color: #263c7a;
    font-weight: 600;
    width: 45%;
}

.associated-fields div div span{
    font-size: 13px;
    font-weight: 500;
    color: #66b6ff;
    width: 50%;
}

.btn-grouping{
    display: flex;
    flex-direction: column;
}

.btn-grouping button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 8px;
    font-size: 10px !important;
    width: 75%;
}

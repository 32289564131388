.favourite-card{
    width: 20em;
    box-shadow: 0 12px 40px rgb(93, 196, 96, 0.4);
    border: 2px solid rgb(93, 196, 96);
    border-radius: 20px;
    padding: 5px;
}

.not-favourite-card{
    width: 20em;
    box-shadow: 0 12px 40px rgb(0, 0, 0, 0.4);
    border: 2px solid #fff;
    border-radius: 20px;
    padding: 5px;
}

.not-favourite-card .header-card, .favourite-card .header-card{
    display: flex;
    justify-content: space-between;
}

.not-favourite-card .body-card, .favourite-card .body-card{
    display: flex;
    flex-direction: column;
    padding: 15px 25px 5px;
}

.footer-card{
    display: flex;
    justify-content: flex-end;
    margin: 0 10px 10px 0;
}

.footer-card div{
    line-height: 110%;
    cursor: pointer;
}

.footer-card div small{
    font-weight: 600;
    color: #2e57c9;
    font-size: 12px;
}

.underline-footer{
    border-top: 1px solid #2e57c9;
    width: 0%;
    transition: .4s;
}

.footer-card div small:hover ~ .underline-footer{
    width: 100%;
}

.data-header{
    font-size: 14px;
    font-weight: 500;
    color: #66b6ff;
}

.data-response{
    color: #263C7A;
    font-weight: 500;
}

.not-favourite-card .credentials-corner{
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 15px;
    right: 15px;
    box-shadow: 1px rgb(0, 0, 0, 0.12);
    border: 1px solid rgb(0, 0, 0, 0.05);
}

.favourite-card .credentials-corner{
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: 15px;
    right: 15px;
    box-shadow: 1px rgb(0, 0, 0, 0.12);
    border: 2px solid rgb(93, 196, 96);
}

.credentials-card-icon{
    font-size: 2em;
}

.selected-credentials{
    font-size: 28px;
    color: #66b6ff;
}

.card-title{
    color: #263C7A;
    font-weight: 600;
}

.card-detail{
    font-size: 12px;
    font-weight: 500;
    line-height: 110%;
}

.bearer-token input{
    border-left: none;
}

.alert-danger{
    text-align: center;
    font-size: 14px;
    padding: 10px;
}

.wa-selector-label{
    color: #919191;
    font-size: 15px;
}

.green-quality-rating{
    width: 16px;
    height: 16px;
    border: 2px solid rgb(66, 171, 73);
    background-color: rgb(66, 171, 73, 0.7);
    border-radius: 50%;
    margin-left: 10px;
}

.red-quality-rating{
    width: 16px;
    height: 16px;
    border: 2px solid rgb(209, 5, 42);
    background-color: rgb(209, 5, 42, 0.7);
    border-radius: 50%;
    margin-left: 10px;
}

.yellow-quality-rating{
    width: 16px;
    height: 16px;
    border: 2px solid rgb(239, 184, 16);
    background-color: rgb(239, 184, 16, 0.7);
    border-radius: 50%;
    margin-left: 10px;
}

.wa-selector-box{
    background-color: #66b6ff;
    color: #fff;
}

.wa-selector-box option{
    background-color: #fff;
    color: #000;
}
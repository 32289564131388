.ai-container {
    position: relative;
    min-width: 550px;
    max-width: 650px;
    height: 100%;
    border-radius: 20px;
    padding: 30px 15px 5px 15px;
    box-sizing: border-box;
    background: linear-gradient(308deg, rgba(159,214,252,1) 8%, rgba(20,72,254,0.7595413165266106) 35%);
    box-shadow: 14px 14px 20px rgb(38, 60, 122, 0.5), -14px -14px 20px white;
}

.res-container {
    position: relative;
    width: 450px;
    height: 100%;
    border-radius: 20px;
    padding: 40px;
    box-sizing: border-box;
    box-shadow: 14px 14px 20px rgb(38, 60, 122, 0.5), 0 0 20px rgb(38, 60, 122, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background-color: #fff;
  }

.ai-brand-logo {
    height: 100px;
    width: 100px;
    background: url("../img/little-bot.webp");
    background-size: cover;
    margin: auto;
    border-radius: 50%;
    box-sizing: border-box;
    box-shadow: 0 0 10px #cbced1, 0 0 10px white;
}

.ai-brand-title {
    margin-top: 25px;
    font-weight: 600;
    color: #fff;
    letter-spacing: 1px;
    text-shadow: 1px 1px 10px rgb(255, 255, 255, 0.5);
    text-align: center;
}

.res-brand-title {
    font-weight: 600;
    color: rgb(38, 60, 122, 0.8);
    letter-spacing: 1px;
    text-align: center;
    text-shadow: 3px 3px 10px rgb(38, 60, 122, 0.5);
    margin-bottom: 20px;
}

div.Typewriter, .res-text{
    color: rgb(0, 0, 0, 0.7);
    font-weight: 500;
    margin: 0;
    font-size: 18px;
    text-align: center;
}

.ai-inputs {
    text-align: left;
    margin-top: 20px;
    color: #fff;
}

.ai-input, .ai-textarea, .ai-button, .res-button {
    width: 100%;
    border: none;
}

.ai-label {
    margin-left: 5px;
    font-weight: 500;
    font-size: 14px;
}

.ai-input::placeholder, .ai-textarea::placeholder {
    color: gray;
}

.ai-input, .ai-textarea {
    background: #ecf0f3;
    padding: 10px;
    padding-left: 20px;
    height: 40px;
    font-size: 14px;
    border-radius: 50px;
    box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
}

.ai-textarea {
    height: 150px;
    border-radius: 15px;
}

.ai-button {
    width: 30vw;
    color: rgb(38, 60, 122, 0.8);
    font-weight: bold;
    box-shadow: -0.5px -0.5px 4px #fff, 1px 1px 4px #9094a3;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    font-weight: 600;
    border-radius: 40px;
    padding: 10px;
    background-color: #ebecf0;
    text-shadow: 3px 3px 10px rgb(38, 60, 122, 0.4);
    margin-top: 20px;
}

.ai-button:hover {
    box-shadow: -2px -2px 5px #fff, 2px 2px 5px #babecc;
    background-color: rgb(194, 217, 255);
}

.ai-button:active {
    box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

.res-button {
    color: rgb(38, 60, 122, 0.8);
    font-weight: bold;
    box-shadow: -0.5px -0.5px 4px #fff, 1px 1px 4px #9094a3;
    transition: all 0.2s ease-in-out;
    cursor: none;
    font-weight: 600;
    border-radius: 40px;
    padding: 10px;
    background-color: #ebecf0;
    text-shadow: 3px 3px 10px rgb(38, 60, 122, 0.4);
    margin-top: 30px;
}
.res-button:hover {
    box-shadow: -2px -2px 5px #fff, 2px 2px 5px #babecc;
}
.res-button:active {
    box-shadow: inset 1px 1px 2px #babecc, inset -1px -1px 2px #fff;
}

input[type='number'], input[type='text'], textarea.ai-textarea{
    color: rgb(0, 0, 0, 0.7);
    font-size: 16px;
    font-weight: 500;
}

.ai-button:disabled{
    background-color: rgb(235, 236, 240, 0.6);
    color: rgb(0, 0, 0, 0.5);
    text-shadow: none;
    box-shadow: none;
    cursor: default;
}

.mg-info-icon{
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
}

.info-tooltip{
    max-width: 400px;
    z-index: 10;
    background-color: #000;
}

.label-height-line{
    line-height: 100%;
}

.messages-counter{
    font-size: 12px;
    margin-right: 20px;
    color: #fff;
}

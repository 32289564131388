.example-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

#myGrid {
  flex: 1 1 0px;
  width: 100%;
}

.table-height{
  height: 500px !important;
  overflow-y: scroll;
  overflow-x: scroll;
}
.table-height::-webkit-scrollbar{
  width: 6px;
}

#table::-webkit-scrollbar, .ag-body-viewport::-webkit-scrollbar {
  width: 6px;
  background-color: transparent;
}

#table::-webkit-scrollbar-thumb, .ag-body-viewport::-webkit-scrollbar-thumb{
  background-color: gray;
  border-radius: 5px;
}

.ag-cell-wrapper{
  line-height: initial;
}

.bg-cron-complete{
  background-color: #5AC15D;
  padding: 0px 3px;
  border-radius: 1px;
}

.bg-cron-active{
  background-color: #FFC107;
  padding: 0px 3px;
  border-radius: 1px;
}

.btn-xs{
  padding: 2px 4px;
  font-size: 12px;
}

body.dark .text-darkMode{
  color: #CCCCCC;
}

.text-darkMode{
  color: #707070;
}

.collapse-width{
  max-width: 402px;
}

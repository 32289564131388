/* Google Font Import - Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', 'Arial';
}

:root{
    /* ===== Colors ===== */
    --body-color: #fff;
    --sidebar-color: #263c7a;
    --primary-color: #66b6ff;
    --primary-color-light: #F6F5FF;
    --toggle-color: #919191;
    --text-color: #263c7a;

    /* ====== Transition ====== */
    --tran-03: all 0.2s ease;
    --tran-03: all 0.3s ease;
    --tran-04: all 0.3s ease;
    --tran-05: all 0.3s ease;
}

body{
    min-height: 100vh;
    background-color: var(--body-color);
    transition: var(--tran-05);
}

::selection{
    background-color: var(--primary-color);
    color: #fff;
}

body.dark{
    --body-color: #18191a;
    --sidebar-color: #242526;
    --primary-color: #3a3b3c;
    --primary-color-light: #3a3b3c;
    --toggle-color: #fff;
    --text-color: #fff;
}

/* ===== Sidebar ===== */
 .sidebar{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 250px;
    padding: 10px 14px;
    background: var(--sidebar-color);
    transition: var(--tran-05);
    z-index: 100;  
}
.sidebar.close{
    width: 88px;
}

/* ===== Reusable code - Here ===== */
.sidebar li{
    height: 40px;
    list-style: none;
    display: flex;
    align-items: center;
    margin-top: 5px;
}

#sidebar-box-2{
    margin-bottom: 0;
}

.sidebar-icon{
    color: #fff;
    font-size: 20px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-icon-md{
    color: #fff;
    font-size: 28px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar .icon{
    min-width: 60px;
    border-radius: 6px;
}

.sidebar .icon{
    min-width: 40px;
    border-radius: 6px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.sidebar .icon-img{
    min-width: 40px;
    border-radius: 50%;
    height: auto;
    margin: 0 10px 0 6px;
}

.sidebar.close .icon-img{
    min-width: 40px;
    border-radius: 50%;
    height: auto;
    margin: 0 0 0 10px;
    transition: var(--tran-05);
}

.sidebar.close .icon{
    min-width: 60px;
}

.sidebar .text,
.sidebar .icon{
    color: #fff;
    transition: var(--tran-03);
}

.sidebar .icon2{
    color: #263c7a !important;
    transition: var(--tran-03);
}

.sidebar .text{
    font-size: 17px;
    font-weight: 500;
    white-space: nowrap;
    opacity: 1;
}
.sidebar.close .text{
    opacity: 0;
}
/* =========================== */

.sidebar header{
    position: relative;
}

.sidebar header .image-text{
    display: flex;
    align-items: center;
}
.sidebar header .logo-text{
    display: flex;
    flex-direction: column;
}
header .image-text .name {
    margin-top: 2px;
    font-size: 18px;
    font-weight: 600;
}

header .image-text .profession{
    font-size: 16px;
    margin-top: -2px;
    display: block;
}

.sidebar header .image-large{
    background-image: url(../img/sidebaricon-large-brand.png);
    height: 80px;
    background-repeat: no-repeat;
    background-position: 50% 40%;
}

.sidebar header .image-short{
    background-image: url(../img/sidebaricon-white-short-brand.png);
    /* background-image: url(../img/sidebaricon-white.png); */
    background-repeat: no-repeat;
    height: 80px;
    background-position: 50% 50%;
}

.sidebar header .image img{
    display: flex;
}

.sidebar header .toggle{
    position: absolute;
    top: 50%;
    right: -25px;
    transform: translateY(-50%) rotate(180deg);
    height: 25px;
    width: 25px;
    color: var(--sidebar-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    cursor: pointer;
    transition: var(--tran-05);
}

body.dark .sidebar header .toggle{
    color: var(--text-color);
}

.sidebar.close .toggle{
    transform: translateY(-50%) rotate(0deg);
}

.sidebar .menu{
    margin-top: 20px;
    margin-bottom: 20px;
}

.collapsible .icon, .collapsible .text{
    color: #fff !important;
}

.sidebar li a, .sidebar li .collapsible{
    list-style: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    border-radius: 6px;
    text-decoration: none;
    transition: var(--tran-03);
}

.sidebar li a:hover, .sidebar li .collapsible:hover{
    background-color: #fff;
}

.sidebar li a:hover .icon, .sidebar li a:hover .sidebar-icon, .sidebar li a:hover .sidebar-icon-md, .collapsible:hover .sidebar-icon, .collapsible:hover .sidebar-icon-md, .sidebar li .collapsible:hover .icon,
.sidebar li a:hover .text, .sidebar li .collapsible:hover .text{
    color: var(--sidebar-color) !important;
}
body.dark .sidebar li a:hover .icon, .sidebar li .collapsible .icon,
body.dark .sidebar li a:hover .text, .sidebar li .collapsible .text{
    color: #000;
}

.sidebar .menu-bar{
    height: calc(100% - 80px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: scroll;
}
.menu-bar::-webkit-scrollbar{
    display: none;
}
.sidebar .menu-bar .mode{
    border-radius: 6px;
    background-color: var(--primary-color-light);
    position: relative;
    transition: var(--tran-05);
}

.menu-bar .mode .sun-moon{
    height: 40px;
    width: 60px;
}

.mode .sun-moon i{
    position: absolute;
}
.mode .sun-moon i.sun{
    opacity: 1;
}
body.dark .mode .sun-moon i.sun{
    opacity: 0;
}
body.dark .mode .sun-moon i.moon{
    opacity: 1;
}

.menu-bar .bottom-content .toggle-switch{
    position: absolute;
    right: 0;
    height: 100%;
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    cursor: pointer;
}
.toggle-switch .switch{
    position: relative;
    height: 22px;
    width: 40px;
    border-radius: 25px;
    background-color: var(--toggle-color);
    transition: var(--tran-05);
}

.switch::before{
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: 50%;
    left: 5px;
    transform: translateY(-50%);
    background-color: var(--sidebar-color);
    transition: var(--tran-04);
}

body.dark .switch::before{
    left: 20px;
}

.home{
    position: absolute;
    top: 0;
    top: 0;
    left: 280px;
    height: 100vh;
    width: calc(100% - 280px);
    transition: all .4s ease;
}

.home-grid{
    position: absolute;
    background-color: var(--body-color);
    transition: var(--tran-05);
}

.home .text{
    font-size: 30px;
    font-weight: 500;
    color: var(--text-color);
    padding: 15px 0 0px 0;
}


.subtitle{
    font-weight: 400;
    font-size: 18px;
    color: #66b6ff;
}

.titles-margin-close{
    margin-left: 10px !important;
}

.titles-margin-open{
    margin-left: 75px !important;
}

.sidebar.close ~ .home{
    left: 90px;
    height: 100vh;
    width: calc(100% - 90px);
}

body.dark .home .text{
    color: var(--text-color);
}

.form-label{
    color: #919191
}

.text-deco{
    text-decoration: none;
}

.pointer{
    cursor: pointer;
}

.user-font{
    color: #919191;
    font-size: 14px;
    line-height: 25px;
}

.normal-line{
    line-height: 25px ;
}

.bx-icon-size{
    font-size: 23px;
}


#msgHoras, #msgMinutos, #msgDia, #msgHoras2, #msgMinutos2, #msgHoras3, #msgMinutos3, #oneDayPerMonth{
    width: 45px;
    text-align: center;
}

.monthSelectStyle{
    color: #919191;
}

.title, .text-color{
    color: #919191;
}

.text-instructions{
    color: #ffffff
}

body.dark .title, body.dark .text-color, body.dark .text-instructions{
    color: #ffffff
}

.ol-size{
    font-size: 20px;
}

.ol-size li{
    margin-bottom: 25px;
}

.icon-custom{
    border: 1px solid #919191;
    border-radius: 2px;
}

body.dark .icon-custom{
    border: 1px solid #ffffff;
    border-radius: 2px;
}

.p-alert{
    padding: 3px 8px 2px;
}

.btn-darkblue{
    background: #07153A;
    color: #fff;
}

.btn-darkblue:hover{
    background: #040e29;
    color: #fff;
}

.btn-lightblue{
    background-color: #66b6ff;
    color: #ffffff;
}

.btn-lightblue:hover{
    background-color: #4795dd;
    color: #ffffff;
}

.btn-outline-darkblue{
    background-color: transparent;
    border-color: #07153A;
    color: #07153A;
}

.btn-outline-darkblue:hover{
    background-color: #07153A;
    border-color: #07153A;
    color: #fff;
}

body.dark .btn-outline-darkblue{
    background-color: transparent;
    border-color: #fff;
    color: #fff;
}

body.dark .btn-outline-darkblue:hover{
    background-color: #fff;
    border-color: #fff;
    color: #000;
}

.text-91{
    color: #919191;
}

body.dark .text-91{
    color: #fff;
}

.btn-darkblue-static, .btn-darkblue-static:hover{
    background: #07153A;
    color: #fff;
}

.btn-91{
    background-color: #919191;
    color: #ffffff;
}

.btn-91:hover{
    background-color: #666666;
    color: #ffffff;
}

.btn-outline-light:hover{
    color: #242528 !important;
}

.btn-outline-light2{
    background-color: transparent !important;
    border-color: #ffffff !important;
    color: #ffffff !important;
}

.btn-outline-light2:hover{
    background-color: #66b6ff !important;
    border-color: #66b6ff !important;
    color: #ffffff !important;
}

.btn-outline-lightblue-white, .btn-outline-lightblue-white:hover{
    background-color: #ffffff;
    border-color: #07153A;
    color: #07153A;
}

.btn-outline-light:hover{
    color: #263c7a !important;
}

.btn-outline-info:hover{
    color: #fff !important;
}


.btn-outline-lightblue{
    border-color: #66b6ff;
    background-color: transparent;
    color: #66b6ff;
}

.btn-outline-lightblue:hover{
    border-color: #66b6ff;
    background-color: #66b6ff;
    color: #fff;
}

.btn-lightblue-to-outline{
    background-color: #66b6ff;
    color: #ffffff;
}

.btn-lightblue-to-outline:hover{
    border-color: #66b6ff;
    background-color: transparent;
    color: #66b6ff;
}

.btn-lightblue-to-darkblue{
    background-color: #66b6ff;
    color: #ffffff;
}

.btn-lightblue-to-darkblue:hover{
    background-color: #07153A;
    color: #ffffff;
}

.text-mediumgray{
    color: #f6f6f6
}

.btn-darkblue-to-lightblue{
    background: #07153A;
    color: #fff;
}

.btn-darkblue-to-lightblue:hover{
    background-color: #66b6ff;
    color: #ffffff;
}

.btn-outlinedarkblue-to-lightblue{
    background-color: transparent;
    border-color: #07153A;
    color: #07153A;
}

.btn-outlinedarkblue-to-lightblue:hover{
    background-color: #66b6ff;
    border-color: #66b6ff;
    color: #ffffff;
}

.btn-darkblue-to-outline{
    background: #07153A;
    color: #fff;
}

.btn-darkblue-to-outline:hover{
    background-color: transparent;
    border-color: #07153A;
    color: #07153A;
}

.text-mediumblue{
    color: #263c7a !important;
}

body.dark .text-mediumblue{
    color: #fff !important;
}

.btn-mediumblue{
    background-color: #263c7a;
    border-color: #263c7a;
    color: #fff;
}

.btn-mediumblue:hover{
    background-color: #192b5e;
    border-color: #192b5e;
    color: #fff;
}

.bg-lightblue{
    background-color: #66b6ff;
}

.bg-mediumblue{
    background-color: #263c7a;
}

.bg-mediumblue-op{
    background-color: #263c7a;
    opacity: 0.8;
}

.bg-darkblue{
    background-color: #07153A;
}

.bg-darkblue-op{
    background-color: #07153A;
    opacity: 0.8;
}

.bg-91-table{
    background-color: #919191;
    color: #fff;
}

.bg-lightblue-table{
    background-color: #66b6ff;
    color: #fff;
}
.bg-lightblue-table:hover{
    background-color: rgb(102, 182, 255, 0.8);
}

.bg-mediumblue-table{
    background-color: #263c7a;
    color: #fff;
}
.bg-mediumblue-table:hover{
    background-color: rgb(38, 60, 122, 0.8);
}

.btn-outline-mediumblue{
    border-color: #263c7a;
    background-color: transparent;
    color: #263c7a;
}

.btn-outline-mediumblue:hover{
    border-color: #263c7a;
    background-color: #263c7a;
    color: #fff;
}

body.dark .btn-outline-mediumblue{
    border-color: #fff;
    background-color: transparent;
    color: #fff;
}

body.dark .btn-outline-mediumblue:hover{
    border-color: #fff;
    background-color: #fff;
    color: #000;
}

.borderside-darkblue{
    border-right: 4px solid #07153A;
}

.alert-darkblue{
    color: #07153A;
    border: 1px solid #66b6ff;
    background-color: #fff;
}

.sun{
    color: #263c7a;
}

body.dark .bx-chevron-right{
    color: #000 !important;
}

.border-primary{
    border-color: #263c7a !important;
}

.bg-91{
    background-color: #919191 !important;
}


.customer-plan{
    position: relative;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 0 5px;
    z-index: 99999;
}

.font10{
    font-size: 10px;
}

.font13{
    font-size: 13px;
}

.btn-outline-information{
    border: none;
}

.text-lightblue{
    color: #66b6ff;
}

.text-blackseidor{
    color: #242528;
}

.icon-size{
    font-size: 20px;
    margin-right: 4px;
}

.hoursystem{
    width: 125px !important;
    font-size: 14px;
    text-align: end;
    color: #242528;
}

.btn-group-width{
    width: calc(50% - 48px);
    height: 31px;
    margin-top: auto;
}

.react-datepicker__input-container{
    display: flex !important;
}

.react-datepicker__input-container input{
    width: 130px !important;
    padding: 0 16px;
    margin: 0 0 0 20px;
}

.prof-name-font{
    color: #fff;
    max-width: 100%;
    font-size: 17px;
    font-weight: 500;
    opacity: 1;
    line-height: 1.1;
}

.sidebar.close .prof-name-font{
    opacity: 0 !important;
}

.bottom-content li{
    transition: var(--tran-05);
    border-radius: 6px;
}

.bottom-content li:hover{
    background-color: #fff;
    border-radius: 6px;
    height: auto;
}

.bottom-content li:hover .prof-name-font{
    color: #263c7a;
}

.bottom-content .user-li li{
    padding: 0 147px 0 0 !important;
}

.bottom-content .user-li li:hover{
    padding: 0 147px 0 0 !important;
}

body.dark .bottom-content li:hover .prof-name-font{
    color: #000;
}

.btn-check-width{
    width: 100%;
}

body{
    background-image: url(../img/img7.png), url(../img/img2.png);
    background-position: left bottom, right top;
    background-repeat: no-repeat, no-repeat;
    background-size: 1000px;
    background-position: 260% -185%, -45% 160%;
    background-attachment: fixed, fixed;
}

.info-icon{
    transition: .2s;
    cursor: pointer;
    color: #66b6ff;
    font-size: 24px;
    border-radius: 50%;
}

.info-icon:hover{
    transform: scale(1.1);
    color: #263c7a;
}

.margin-template{
    margin-left: 75px !important;
}

.newQrButtonWidth{
    width: 150px;
}

.plan-cards{
    box-shadow: 0 0 10px 1px #263c7a;
    border-radius: 5px;
    width: 220px;
    padding: 15px 10px;
    margin: 0 10px;
    transition: .2s;
}

.plan-cards .price-details{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.plan-cards:hover{
    transform: scale(1.05);
}

.plan-cards div p{
    font-weight: 500;
    margin-bottom: 10px;
}

.plan-cards div small{
    color: #263c7a;
    font-weight: 500;
}

.plan-cards i{
    font-size: 40px;
    margin: 5px 0 10px;
}

.plan-cards ul{
    list-style: none;
    padding: 0;
    font-size: 13px;
    margin: 10px 0 40px 0;
}

.plan-cards ul li::before{
    content: '✓';
    margin-right: 10px;
    color: #66b6ff;
    font-weight: 600;
}

.plan-switch p{
    font-weight: 700;
    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 17px;
    color: rgb(0, 0, 0, 0.6);
    margin: 0 15px;
    transition: .2s;
}

.plan-switch .switch-checked{
    text-shadow: 0px 0px 10px #263c7a;
    color: rgb(38, 60, 122, 0.8);
    transition: .2s;
}

.plan-switch #btn-month {
    border-top-left-radius: 25px;
    border-bottom-left-radius: 25px;
    width: 50%;
}

.plan-switch #btn-year {
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    width: 50%;
}

.plan-cards .price-info{
    display: flex;
    justify-content: center;
    align-items: center;
}

.plan-cards .price-info s{
    color: rgb(24, 23, 28, 0.8);
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
}

.plan-cards .price-info .price-currency{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    font-size: 14px;
    margin: 0 0 0 5px;
}

.plan-cards .price-info .price-currency small{
    font-weight: 600;
    color: gray;
    line-height: 120%;
}

.disclaimer-message{
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    transition: .5s;
    overflow: hidden;
}

.disclaimer-without-message{
    height: 0vh;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: .5s;
}

.avisolegal-iframe{
    width: 95vw;
    height: 80vh;
    border: 1px solid #dee2e6;
}

.tutorials-iframe{
    max-width: 560px;
}

.navCollapse{
    transition: .2s;
    display: flex !important;
    height: 0px !important;
    opacity: 0 !important;
    margin: 0 !important;
    overflow: hidden !important;
}

input#collapse-checkbox:checked ~ .navCollapse{
    transition: .2s;
    display: flex !important;
    flex-direction: column !important;
    height: 100px !important;
    opacity: 1 !important;
    margin: 5px 0 20px 0 !important;
}

.delete-subscription-screen{
    /* width: 100vw; */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.delete-sub-form{
    overflow: hidden;
    width: 50vw;
}

.delete-sub-form-first-part{
    position: relative;
    right: 0%;
}

.delete-sub-form-second-part{
    position: relative;
    right: 0%;
}

.delete-category-radio{
    margin-left: 8px;
}

.delete-category-radio span{
    font-size: 14px;
    font-weight: 500;
}

.delete-arrow{
    font-size: 16px;
}

#delete-sub-textarea{
    font-size: 13px;
}
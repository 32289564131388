.card {
    max-width: 270px;
    position: relative;
    /* bottom: -325px; */
    top: calc(100vh - 332px); /* ViewportHeight - Alto total del UserBox (y un mini margen) */
    left: 91px;
    padding: 12px;
    clip-path: circle(0% at 0% 94%);
    transition: all ease-in-out 0.3s;
    background-color: #263C7A;
    border: 2px solid #919191;
    color: #fff;
    font-size: 14px;
    z-index: 100;
}
.card-sidebaropen{
    left: 253px;
}
.card-information{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.card-actions{
    padding: 7px 12px;
    transition: .3s;
    border-radius: 4px;
    cursor: pointer;
    color: #fff;
}
.card-actions:hover{
    background-color: #fff;
    color: #263C7A;
}
.profile-img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
}
.user-info:checked ~ .card, .user-info:checked ~ .card:focus {
    clip-path: circle(75%);
    border-radius: 5px;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.12), 0px 3px 18px rgba(0, 0, 0, 0.08);
    outline: none;
}
.user-info:checked ~ .card:focus {
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.12), 0px 3px 18px rgba(0, 0, 0, 0.08), 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
}


.horizontal-line{
    width: 100%;
    height: 1px;
    background-color: #fff;
    margin: 15px 0 8px 0;
}

.delay-style{
    width: 30px;
    text-align: center;
    margin: 0 5px 0 10px;
}

input.delay-style::-webkit-outer-spin-button,
input.delay-style::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.osc-style{
    margin: 10px 0 0 0;
}

.osc-style small{
    max-width: 280px;
}

.user-toggle-switch{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.user-toggle-switch .user-switch{
    position: relative;
    height: 22px;
    width: 40px;
    border-radius: 25px;
    background-color: transparent;
    transition: var(--tran-05);
    border: 1px solid #919191;
}

.user-toggle-switch .user-switch::before{
    content: '';
    position: absolute;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    background-color: #919191;
    transition: var(--tran-04);
}


input[id="oscilacion"]:checked ~ .user-toggle-switch .user-switch{
    background-color: #919191;
    border: 1px solid #919191;
}

input[id="oscilacion"]:checked ~ .user-toggle-switch .user-switch::before{
    left: 5px;
    background-color: #263C7A;
}

.enabled-disabled-box{
    align-items: center;
    width: 65px;
}
.ag-theme-alpine {
    --ag-foreground-color: #000;
    --ag-background-color: #fff;
    --ag-header-foreground-color: #fff;
    --ag-header-background-color: #263c7a;
    --ag-odd-row-background-color: #fff;
    --ag-header-column-resize-handle-color: #fff;
    --ag-font-size: 14px;
    --ag-font-family: 'Poppins';
    --ag-border-radius: 10px !important;
}


.table-container{
    height: 100vh;
}
.labels-size{
    font-size: 14px;
}

.category-radio{
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.category-radio span{
    font-weight: 500;
}

.category-radio small{
    font-size: 12px;
    margin-bottom: 10px;
}

.scroll-y-overflow{
    overflow-y: scroll;
    overflow-x: hidden;
}

.scroll-y-overflow::-webkit-scrollbar{
    width: 6px;
    background-color: transparent;
}

.scroll-y-overflow::-webkit-scrollbar-thumb{
    background-color: gray;
    border-radius: 5px;
}

.ext-config-page{
    position: relative;
    right: 0%;
}

.button-agrupation{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.wa-message-box{
    width: 519px;
    height: 254px;
    border: 1px solid #919191;
    border-radius: 5px;
    padding: 12px;
    overflow-y: scroll;
}

.wa-message-box-modal{
    border: 1px solid #919191;
    border-radius: 5px;
    padding: 12px;
}

.file-url-box{
    border: 1px solid #919191;
    border-radius: 5px;
    padding: 12px;
    overflow: hidden;
}

.file-url-box a{
    text-decoration: none;
    color: #000;
}

.wa-header{
    font-size: 20px;
    font-weight: 500;
}

.wa-body{
    font-size: 15px;
}

.wa-footer{
    font-size: 14px;
    color: #919191;
}

.btn-modal-size{
    padding: 0.1em 2em;
    font-size: 14px;
}

.integration-list-group{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.item-buttons{
    display: flex;
    justify-content: flex-end !important;
}

/* ---------------- Integration Card ---------------- */
.card-container .integration-card p {
    margin: 0;
    font-size: 13px;
}

.card-container .integration-card p:not(:last-child) {
    margin-bottom: .4em;
}

.card-container .integration-card p:last-child{
    margin-top: 1.5em;
}

.card-container .integration-card .integration-logo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 8px 0 0 0;
}

.card-container .integration-card .integration-logo img {
    background-color: rgb(255, 255, 255, 0.5);
    width: 30%;
    border-radius: 5px;
}

.integration-logo {
    display: flex;
    justify-content: center;
    align-items: center;
}

.box-text-integ{
    display: flex;
    justify-content: center;
    align-items: center;
}


.box-integation-new{
    display: flex;
    justify-content: center;
    align-items: center;  
}

#toogle-switch-2{
    padding: 0;
}

#text-toogle-1{
    margin: 0px 30px 0px 0px;
    font-weight: bold;
    transition: all .5s ease;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
}

#text-toogle-1-checked{
    margin: 0px 30px 0px 0px;
}

#text-toogle-2{
    margin: 0px 0px 0px 30px;
}

#text-toogle-2-checked{
    font-weight: bold;
    margin: 0px 0px 0px 30px;
    transition: all .5s ease;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
}

.integration-logo img {
    background-color: rgb(255, 255, 255, 0.5);
    width: 50%;
    border-radius: 5px;
}

.text-mediumgray{
    color: #707070;
}

.status-alert div{
    height: 65%;
    margin: 0;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;
    font-size: 12px;
}

.renderer-weight{
    font-weight: 500;
    font-size: 13px;
}

.card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: #224;
}

.card-container:hover {
    transform: scale(1.01);
    background: rgb(175,222,255);
    background: linear-gradient(166deg, rgba(175,222,255,1) 14%, rgba(55,99,252,0.7595413165266106) 100%);
    color: #232222;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.25);

}

.bg0-circled{
    background: rgb(175,222,255);
    background: linear-gradient(293deg, rgba(175,222,255,1) 14%, rgba(55,99,252,0.7595413165266106) 100%);
}

.bg1-circled{
    background: rgb(175,222,255);
    background: linear-gradient(293deg, rgba(175,222,255,1) 14%, rgba(55,99,252,0.7595413165266106) 100%);
}

.bg2-circled{
    background: rgb(175,222,255);
    background: linear-gradient(293deg, rgba(175,222,255,1) 14%, rgba(55,99,252,0.7595413165266106) 100%);
}


.bg3-circled{
    background: rgb(175,222,255);
    background: linear-gradient(293deg, rgba(175,222,255,1) 14%, rgba(55,99,252,0.7595413165266106) 100%);
}

.card-container .integration-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 15px 3px 3px 15px;
    border: 1px solid rgba(255, 255, 255, .25);
    border-radius: 8px;
    
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(15px);
    width: 100%;
}

.integration-card-footer {
    font-size: 0.65em;
    color: #446;
}
/* ---------------- Integration Card ---------------- */
/* ---------------- Integration Item List ---------------- */
.integration-list-item{
    border: 1px solid #fff;
    box-shadow: 0 0 5px #263c7a;
    border-radius: 5px;
    padding: 10px 0;
}
/* ---------------- Integration Item List ---------------- */
/* ---------------- Integration Wizard ---------------- */
.wizard-mini-card-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: 1px solid transparent;
    color: #263C7A;
    padding: 0 25px 10px;
}

.wizard-card-bordered{
    position: relative;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: 1px 1px 20px #3e73a5;
    color: #66b6ff;
    transition: .2s;
    z-index: 100;
}

.wizard-mini-card-icon svg{
    font-size: 50px;
}

.wizard-mini-card-icon span{
    font-size: 15px;
    font-weight: 600;
}

.select-table-fields{
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-table-fields .col-name input, .select-table-fields .all-cols input, .optional-config-page div input{
    margin: 0 15px 0 15px;
}

.optional-config-page div{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.select-table-fields .col-name{
    font-size: 14px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.select-table-fields .all-cols{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    margin: 0 0 15px 0;
}

.select-table-fields .col-name label, .select-table-fields .all-cols label{
    font-weight: 500;
    color: rgb(0, 0, 0, 0.6);
}

.select-table-fields .second-column label, .select-table-fields .second-column select{
    font-size: 12px;
}

.select-table-fields .second-column label{
    width: 35%;
}

.select-table-fields .second-column select{
    width: 45%;
}

.select-table-fields .second-column div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
}

.box-extraction-date {
    margin-top: 1.3rem;
    padding: 1rem;
    background-color: #ffffff;
    box-shadow: 0px 2px 17px 1px rgba(140,127,140,1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.box-extraction-date-disabled {
    margin-top: 1.3rem;
    padding: 1rem;
    background-color: rgb(0, 0, 0, 0.1);
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    pointer-events: none;
    color: #959697;
}

.box-extraction-select {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.ext-config-richtext{
    width: 40vw;
    height: 20vw;
    margin: 15px 0px;
    border-style: none;
    border-radius: 10px;
    box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.25);
}


.extraction-schedule-disabled {
    opacity: 0.5; 
    pointer-events: none; 
    transition: opacity 0.3s ease;
}

.richtext-disabled{
    pointer-events: none;
    background-color: rgb(0, 0, 0, 0.1);
}

.optional-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
/* ---------------- Integration Wizard ---------------- */

.btn-integrations{
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn-integrations .btn{
    width: 50%;
}

.platform-selected{
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.platform-selected small{
    color: #263c7a;
    font-size: 14px;
    font-weight: 500;
}

.platform-selected img{
    width: 100px;
}

.select-platform-img{
    display: flex;
    justify-content: center;
    align-items: center;
}

.select-platform-img div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 15px 0 15px;
    height: 50px;
    padding: 10px;
    cursor: pointer;
}

.select-platform-img .selected-logo{
    box-shadow: 0 0 5px #263c7a;
}

.select-platform-img div img{
    width: 100px;
}

#new-integration-modal{
    overflow-y: scroll;
}

#new-integration-modal::-webkit-scrollbar{
    width: 6px;
    background-color: transparent;
}

#new-integration-modal::-webkit-scrollbar-thumb{
    background-color: gray;
    border-radius: 5px;
}

.main-label{
    font-size: 14px;
    font-weight: 500;
    color: #263C7A;
}

#new-integration-modal form input::placeholder{
    text-align: start;
    font-size: 13px;
    font-weight: normal;
}

.ag-layout-normal{
    border-radius: 7px !important;
}

.ag-header-viewport{
    background-color: #66b6ff;
}

.date-expiration{
    flex-direction: column;
}

#form-button {
    border: 0px solid;
    margin: 15px 0px 0px 5px;
}

.box-template{
    display: flex;
    align-items: flex-end;
    margin-right: -132px;
}

#flexSwitchCheckChecked{
    margin: 2px 0px;
    height: 30px;
    width: 55px;
}

#toggleSendMessage{
    margin: 2px 0px;
    height: 30px;
    width: 55px;
}

.box-toogle{
    display: contents;
}

#box-toogle-content {
    justify-content: center;
}

#text-toogle-si{
    margin: 0px;
    padding-right: 65px;
}


#text-toogle-no{
    margin: 0px;
    padding-left: 65px;
}

#text-toogle-no-checked{
    font-weight: bold;
    margin: 0px;
    padding-left: 65px;
    transition: all .5s ease;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
}

#text-toogle-si-checked{
    font-weight: bold;
    margin: 0px;
    padding-right: 65px;
    transition: all .5s ease;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
}

#box-switch{
    padding: 0px;
} 


#no-later{
    margin-right: 25px;
    
}

#no-later-checked{
    transition: all .5s ease;
    font-weight: bold;
    margin-right: 25px;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
}

#yes-now{
    padding-right: 25px;
    margin-left: 25px;
    
}

#yes-now-checked{
    padding-right: 25px;
    transition: all .5s ease;
    margin-left: 25px;
    font-weight: bold;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.3);
}

.active {
    background-color: #4caf50;
    color: white; 
}


.form-check-input[type="checkbox"] {
    background-color: rgb(27, 140, 253);
}

.form-check-input[type="checkbox"]:checked {
    background-color: rgb(27, 140, 253);
}

.form-switch .form-check-input{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 05255, 255, 1%29'/%3e%3c/svg%3e");
}

.form-check-input[type="checkbox"]:focus,
.form-check-input[type="checkbox"]:active {
    outline: none;
    box-shadow: none;
}

#text-form-extrat{
    color: #2d2b2b;
    font-size: 1rem;
}

#text-form-extrat-step4{
    font-size: 1rem;
    color: #2d2b2b;
    
}

#text-form-data{
    color: #2d2b2b;
    font-size: 0.8rem;
}

#text-color-font{
    font-size: 1.8rem;
}

#frecuencia:disabled{
    pointer-events: none;
    background-color: rgb(0, 0, 0, 0.1);
}

.table-powerbi{
    margin-right: 28px;

}

.box-databases, .box-apirest{
    display: flex;
    align-items: center;
    justify-content: center;
}
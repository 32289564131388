.template-labels{
    font-size: 14px;
}

#newTemplateForm input::placeholder, #newTemplateForm select{
    font-size: 14px;
}

.category-radio{
    display: flex;
    flex-direction: column;
    margin-left: 8px;
}

.category-radio span{
    font-weight: 500;
}

.category-radio small{
    font-size: 12px;
    margin-bottom: 10px;
}

.template-form{
    overflow: hidden;
}

.template-form-first-part{
    position: relative;
    right: 0%;
}

.template-form-second-part{
    position: relative;
    right: 0%;
}

.button-agrupation{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.wa-message-box{
    width: 519px;
    height: 254px;
    border: 1px solid #919191;
    border-radius: 5px;
    padding: 12px;
    overflow-y: scroll;
}

.wa-message-box-modal{
    /* height: 254px; */
    border: 1px solid #919191;
    border-radius: 5px;
    padding: 12px;
    /* overflow-y: scroll; */
}

.file-url-box{
    border: 1px solid #919191;
    border-radius: 5px;
    padding: 12px;
    overflow: hidden;
}

.file-url-box a{
    text-decoration: none;
    color: #000;
}

.wa-header{
    font-size: 20px;
    font-weight: 500;
}

.wa-body{
    font-size: 15px;
}

.wa-footer{
    font-size: 14px;
    color: #919191;
}

.btn-modal-size{
    padding: 0 2em;
    font-size: 12px;
}
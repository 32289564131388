.newUserForm label{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    color: #3C4A5A;
}

.newUserForm{
    min-width: 350px;
    max-width: 440px;
}

.termsandpolicy{
    font-size: 14px;
}

.termsandpolicy a{
    font-weight: 600;
}

.newUserForm input::placeholder{
    font-size: 14px;
    color: #919191;
}

.signUpPageTitle{
    color: #3C4A5A;
    font-weight: 600;
}

.harbinger-logo-azul{
    background-image: url(../img/harbinger-logo-azul192.png);
    height: 120px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
}

.color-login{
    color: #3C4A5A;
}

.price-font{
    color: #263c7a;
    font-weight: 550;
}

.loading-button{
    width: 1rem !important;
    height: 1rem !important;
}

/* @media (max-width: 500px) {
    .d-flex {
        flex-direction: column;
        background-color: aqua;
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
  
    .form-control {
      width: 100%;
    }
  
    .btn-check-width {
      width: 100%;
    }
  
    .mt-5 {
      margin-top: 2rem;
    }
  
    .my-5 {
      margin: 2rem 0;
    }
  } */
.gdrive-icon{
    font-size: 22px;
}

.gdrive-container{
    width: 250px;
    margin: auto;
}

.wa-status-error, .wa-status-pending, .wa-status-server, .wa-status-device, .wa-status-read, .wa-status-played{
    font-size: 18px;
}

.wa-status-error{
    color: #dc3545;
}
.wa-status-pending{
    color: #ffc107;
}
.wa-status-server{
    color: #adb5bd;
}
.wa-status-device{
    color: #adb5bd;
}
.wa-status-read{
    color: #0d6efd;
}
.wa-status-played{
    color: #0d6efd;
}

.templates-type-icon{
    font-size: 25px;
    width: 100%;
}

.standard-icon{
    color: #66b6ff;
}

.ai-icon{
    color: #263c7a;
}
.attachcard {
    max-width: 270px;
    position: absolute;
    bottom: 50px;
    right: 50px;
    padding: 12px;
    clip-path: circle(0% at 94% 94%); /* Define el comportamiento de la caja al aparecer y desaparecer */
    /* clip-path: circle(0% at 0% 94%); */
    transition: all ease-in-out 0.3s;
    background-color: #66b6ff;
    color: #fff;
    font-size: 14px;
    z-index: 100;
}
.attachfilecard:checked ~ .attachcard, .attachfilecard:checked ~ .attachcard:focus {
    clip-path: circle(75%);
    border-radius: 5px;
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.12), 0px 3px 18px rgba(0, 0, 0, 0.08);
    outline: none;
}
.attachfilecard:checked ~ .attachcard:focus {
    box-shadow: 0px 3px 9px rgba(0, 0, 0, 0.12), 0px 3px 18px rgba(0, 0, 0, 0.08), 0px 0px 0px 4px rgba(0, 0, 0, 0.2);
}

.attachfile-position{
    position: relative;
}

.attachfiles-icon{
    font-size: 35px;
    color: #fff;
    background-color: #263c7a;
    border-radius: 50%;
    padding: 10px;
    margin: 5px 20px;
    cursor: pointer;
    transition: .3s;
}

.attachfiles-icon:hover{
    background-color: #07153a;
}

.x-icon{
    font-size: 20px;
}

/* 
lightblue: #66b6ff
mediumblue: #263c7a
lightgray: #919191
darkblue: #07153a
*/